// Processing.js - Page with a middle spinner indicating content is loading
import React from "react";
import { Spinner, Container, Row, Col } from "react-bootstrap";

const Processing = () => {
  return (
    <Container fluid className="vh-100 d-flex justify-content-center align-items-center">
      <Row>
        <Col className="text-center">
          <Spinner animation="border" variant="primary" aria-live="polite">
            <output aria-live="polite" className="visually-hidden">Loading...</output>
          </Spinner>
          <p className="mt-3">Loading page content, please wait...</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Processing;
