import { useState, useEffect } from "react";
import { isAuthenticated } from "../utils/auth";
import { localData } from "../context/processor";

/**
 * Custom hook to watch the session status.
 * @returns {boolean} - Returns whether the session is valid or not.
 */
const useSessionWatch = () => {
  const [sessionValid, setSessionValid] = useState(isAuthenticated());

  useEffect(() => {
    /**
     * Checks the current session validity.
     */
    const checkSession = () => {
      const isValid = isAuthenticated();
      if (isValid !== sessionValid) {
        setSessionValid(isValid);
      }
    };

    /**
     * Handles storage changes across different tabs/windows.
     * @param {StorageEvent} e - The storage event.
     */
    const handleStorageChange = (e) => {
      if (e.key === "session") {
        checkSession();
      }
    };

    /**
     * Handles local storage changes within the same tab.
     */
    const handleLocalStorageChange = () => {
      checkSession();
    };

    // Initial session check
    checkSession();

    // Add event listeners
    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("localStorageChange", handleLocalStorageChange);

    // Observe changes to localData using a custom event
    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function (key, value) {
      originalSetItem.apply(this, arguments);
      if (key === "session") {
        handleLocalStorageChange();
      }
    };

    // Cleanup
    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener(
        "localStorageChange",
        handleLocalStorageChange
      );
      localStorage.setItem = originalSetItem;
    };
  }, [sessionValid]);

  return sessionValid;
};

export default useSessionWatch;

/**
 * Updates the session data and notifies changes within the same tab.
 * @param {Object} data - The session data to save.
 */
export const updateSessionData = (data) => {
  localData("save", "session", data);
  // Notify change within the same tab
  const event = new Event("localStorageChange");
  window.dispatchEvent(event);
};

/**
 * Removes the session data and notifies changes within the same tab.
 */
export const removeSessionData = () => {
  localData("remove", "session");
  // Notify change within the same tab
  const event = new Event("localStorageChange");
  window.dispatchEvent(event);
};
