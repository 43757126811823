import { useState, useEffect } from "react";
import { localData } from "../context/processor";

/**
 * Validate if the slot value is within the allowed range (1-10).
 * @param {number} slot - The slot value to validate.
 * @returns {boolean} - True if valid, otherwise false.
 */
const isValidSlot = (slot) => {
  if (slot >= 1 && slot <= 10) {
    return true;
  } else {
    console.warn(
      `Invalid slot value: ${slot}. Slot should be between 1 and 10.`
    );
    return false;
  }
};

/**
 * Custom hook to listen for changes to the 'slot' key in the session data.
 * @returns {number} - The current value of the 'slot' key in session.
 */
function useSlot() {
  const [slot, setSlot] = useState(() => {
    // Initialize state with the current 'slot' value from session data
    const savedSlot = parseInt(localData("get", "session")?.slot ?? 0, 10);
    return isValidSlot(savedSlot) ? savedSlot : 0;
  });

  useEffect(() => {
    /**
     * Function to handle changes to the session data.
     */
    const handleSessionChange = () => {
      const newSlot = parseInt(localData("get", "session")?.slot ?? 0, 10);
      setSlot(isValidSlot(newSlot) ? newSlot : 0);
    };

    // Observe changes to localData using a custom event
    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function (key, value) {
      originalSetItem.apply(this, arguments);
      if (key === "session") {
        handleSessionChange();
      }
    };

    // Clean up event listener on component unmount
    return () => {
      localStorage.setItem = originalSetItem;
    };
  }, []);

  return slot;
}

export default useSlot;
