// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { isAuthenticated as checkAuth, logout as performLogout, onAuthChange } from "../utils/auth";

// Create the AuthContext
const AuthContext = createContext();

// Hook to use the AuthContext in any component
export const useAuth = () => useContext(AuthContext);

// AuthProvider component to wrap your app and provide the context
export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(checkAuth());

  useEffect(() => {
    // Listen for authentication changes and update the state
    const unsubscribe = onAuthChange((isAuth) => {
      setAuthenticated(isAuth);
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  // Update authentication status on login/logout
  const login = () => setAuthenticated(true);
  const logout = () => {
    performLogout();
    setAuthenticated(false); // This will be triggered by authChangeCallback too, but kept for completeness
  };

  // Memoize the value object to prevent re-creation on every render
  const value = useMemo(() => ({ authenticated, login, logout }), [authenticated]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

// Props validation for AuthProvider
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
