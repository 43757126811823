import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import Directories from "../directories/Directories";
import ChatPage from "../composer/ChatPage";
import Customers from "../customers/customers";
import CustomerPage from "../customers/customer";
import UserActivitiesPage from "../team/UserActivities";
import UserEventsPage from "../team/UserHistory";
import OverviewPage from "../dashboard/overview";

import ProfileDisplay from "../account/profile";

import SupportPage from "../support/support";

import Shortcodes from "../dashboard/shortcodes";
import Dashboard from "../dashboard/Dashboard";
import DocumentDetails from "../directories/DirectoryFile";
import DocumentEditor from "../directories/DirectoryFileEditor";
import Authenticate from "../auth/Authenticate";
import CookiesNotification from "../components/CookiesNotification";
import FourOFour from "../includes/Four0Four";

/**
 * Main App component that handles routing and authentication.
 * @component
 * @returns {JSX.Element}
 */
const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route
            path="/authenticate/"
            element={<PublicRoute element={<Authenticate />} />}
          />

          <Route
            exact
            path="/"
            element={<ProtectedRoute element={<Dashboard />} />}
          />

          <Route
            path="/directories/"
            element={<ProtectedRoute element={<Directories />} />}
          />
          <Route
            path="/directories/:directory/"
            element={<ProtectedRoute element={<Directories />} />}
          />

          <Route
            path="/directories/:directory/:document/"
            element={<ProtectedRoute element={<DocumentDetails />} />}
          />

          <Route
            path="/directories/:directory/:document/editor/"
            element={<ProtectedRoute element={<DocumentEditor />} />}
          />

          <Route
            path="/profile/"
            element={<ProtectedRoute element={<ProfileDisplay />} />}
          />
          <Route
            path="/notifications/"
            element={<ProtectedRoute element={<ProfileDisplay />} />}
          />
          <Route
            path="/settings/"
            element={<ProtectedRoute element={<OverviewPage />} />}
          />
          <Route
            path="/placeholders/"
            element={<ProtectedRoute element={<Shortcodes />} />}
          />

          <Route
            path="/team/"
            element={<ProtectedRoute element={<UserActivitiesPage />} />}
          />

          <Route
            path="/team/:user/"
            element={<ProtectedRoute element={<UserEventsPage />} />}
          />
          <Route
            path="/clients/"
            element={<ProtectedRoute element={<Customers />} />}
          />

          <Route
            path="/clients/:customer/"
            element={<ProtectedRoute element={<CustomerPage />} />}
          />

          <Route
            path="/composer/"
            element={<ProtectedRoute element={<ChatPage />} />}
          />
          <Route
            path="/support/"
            element={<ProtectedRoute element={<SupportPage />} />}
          />
          <Route path="*" element={<ProtectedRoute element={<FourOFour />} />} />
        </Routes>
      </Layout>
      <CookiesNotification />
    </Router>
  );
};

export default App;
