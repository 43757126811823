import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Modal, Form, Button } from "react-bootstrap";
import { runData } from "../context/processor";
import { useReport } from "../context/ReportModal";
import { useProcessing } from "../context/ProcessingModal";
import useSlot from "../hooks/useSlot";
import DOMPurify from "dompurify"; // Added for sanitizing inputs

const DirectoryCreator = ({
  showAddPackageModal,
  setShowAddPackageModal,
  isDocument,
  onAddPackageSuccess,
}) => {
  const [newPackageTitle, setNewPackageTitle] = useState(""); // State for new directory or document title
  const [accessType, setAccessType] = useState(""); // State for access type
  const [documentType, setDocumentType] = useState(""); // State for document type
  const { directory } = useParams(); // Extract directory parameter from the URL
  const { setReport } = useReport();
  const { setProcessing } = useProcessing();
  const slot = useSlot(); // Get user's slot information, assuming a number from 1 to 10

  // Function to handle adding a new directory
  const handleAddDirectory = async (e) => {
    e.preventDefault();
    setShowAddPackageModal(false);
    if (!newPackageTitle.trim()) {
      setReport({
        show: true,
        message: "Directory title cannot be empty.",
        type: "error",
      });
      return false;
    }
    if (!accessType.trim()) {
      setReport({
        show: true,
        message: "Access type cannot be empty.",
        type: "error",
      });
      return false;
    }
    if (accessType.toLowerCase() === "global" && slot >= 7) {
      setReport({
        show: true,
        message: "You are not authorized to create global directories.",
        type: "error",
      });
      return false;
    }
    setProcessing(true);
    try {
      const parent = directory || "root"; // Determine the parent for the new directory
      const data = {
        title: newPackageTitle,
        access: accessType,
        type: "directory",
        parent: parent,
      };
      const response = await runData(data, `/api/directories/new/directory/`);

      if (response.status === 200 && response.data?.code === 200) {
        setNewPackageTitle(""); // Reset title
        setAccessType(""); // Reset access type
        const newDirectory = {
          ...data,
          title: DOMPurify.sanitize(response.data.title),
          reference: response.data.reference,
        };
        onAddPackageSuccess(newDirectory); // Pass the new directory to the parent component
        setReport({
          show: true,
          message: "Your directory has been added successfully!",
          type: "success",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        return true;
      } else {
        setReport({
          show: true,
          message:
            response.data?.message ||
            "An error occurred during adding the directory.",
          type: "error",
        });
        return false;
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message ||
          "An unexpected error occurred during adding the directory.",
        type: "error",
      });
      return false;
    } finally {
      setProcessing(false);
    }
  };

  // Function to handle adding a new document
  const handleAddDocument = async (e) => {
    e.preventDefault();
    setShowAddPackageModal(false);
    if (!newPackageTitle.trim()) {
      setReport({
        show: true,
        message: "Document title cannot be empty.",
        type: "error",
      });
      return false;
    }
    if (!accessType.trim()) {
      setReport({
        show: true,
        message: "Access type cannot be empty.",
        type: "error",
      });
      return false;
    }
    if (!documentType.trim()) {
      setReport({
        show: true,
        message: "Document type must be either Write Online or Upload Static.",
        type: "error",
      });
      return false;
    }
    if (accessType.toLowerCase() === "global" && slot >= 7) {
      setReport({
        show: true,
        message: "You are not authorized to create global documents.",
        type: "error",
      });
      return false;
    }
    setProcessing(true);
    try {
      const parent = directory || "root"; // Determine the parent for the new document
      const data = {
        title: newPackageTitle,
        access: accessType,
        type: documentType,
        parent: parent,
      };
      const response = await runData(data, `/api/directories/new/document/`);

      if (response.status === 200 && response.data?.code === 200) {
        setNewPackageTitle(""); // Reset title
        setAccessType(""); // Reset access type
        setDocumentType(""); // Reset document type
        const newDocument = {
          ...data,
          title: DOMPurify.sanitize(response.data.title),
          reference: response.data.reference,
        };
        onAddPackageSuccess(newDocument); // Pass the new document to the parent component
        setReport({
          show: true,
          message: "Your document has been added successfully!",
          type: "success",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        return true;
      } else {
        setReport({
          show: true,
          message:
            response.data?.message ||
            "An error occurred during adding the document.",
          type: "error",
        });
        return false;
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message ||
          "An unexpected error occurred during adding the document.",
        type: "error",
      });
      return false;
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      {/* Modal for Adding a New Directory or Document */}
      <Modal
        show={showAddPackageModal}
        onHide={() => setShowAddPackageModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isDocument ? "Add New Document" : "Add New Directory"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                {isDocument ? "Document Title" : "Directory Title"}
              </Form.Label>
              <Form.Control
                type="text"
                value={newPackageTitle}
                onChange={(e) => setNewPackageTitle(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Access Type</Form.Label>
              <Form.Control
                as="select"
                value={accessType}
                onChange={(e) => setAccessType(e.target.value)}
                required
              >
                <option value="">Select...</option>
                {slot < 7 && <option value="Global">Global</option>}
                <option value="Company">Company</option>
                <option value="Personal">Personal</option>
              </Form.Control>
            </Form.Group>
            {isDocument && (
              <Form.Group className="mb-3">
                <Form.Label>Content Type</Form.Label>
                <Form.Control
                  as="select"
                  value={documentType}
                  onChange={(e) => setDocumentType(e.target.value)}
                  required
                >
                  <option value="">Select...</option>
                  <option value="Write">Write Online</option>
                  <option value="Upload">Upload Static</option>
                </Form.Control>
              </Form.Group>
            )}
            <div className="d-flex justify-content-between">
              <Button
                variant="secondary"
                onClick={() => setShowAddPackageModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={isDocument ? handleAddDocument : handleAddDirectory}
                disabled={!newPackageTitle}
              >
                {isDocument ? "Add Document" : "Add Directory"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

DirectoryCreator.propTypes = {
  showAddPackageModal: PropTypes.bool.isRequired,
  setShowAddPackageModal: PropTypes.func.isRequired,
  isDocument: PropTypes.bool.isRequired,
  onAddPackageSuccess: PropTypes.func.isRequired, // Added to pass data to parent
};

export default DirectoryCreator;
