import React, { useEffect, useState } from "react";
import { localData } from "../context/processor";

const CookiesNotification = () => {
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const checkCookiesStatus = () => {
      const cookiesStatus = localData("get", "cookies");
      if (!cookiesStatus) {
        // If there is no cookies key at all, show the notification
        setShowNotification(true);
      } else {
        // If cookies are either accepted or rejected, do not show the notification
        setShowNotification(false);
      }
    };

    // Check the cookies status whenever the component mounts
    checkCookiesStatus();

    // Keep watching for changes in the cookies status
    const interval = setInterval(checkCookiesStatus, 1000);
    return () => clearInterval(interval); // Clean up interval when component unmounts
  }, []);

  const handleAcceptCookies = () => {
    // Save cookies acceptance in local storage
    localData("save", "cookies", "accept");
    setShowNotification(false);
  };

  const handleRejectCookies = () => {
    // Save cookies rejection in local storage
    localData("save", "cookies", "reject");
    setShowNotification(false);
  };

  return (
    <>
      {showNotification && (
        <div style={styles.notificationContainer}>
          <div style={styles.notificationContent}>
            <p style={styles.message}>
              We use cookies to ensure you get the best experience on our
              system. Please accept cookies to continue.
            </p>
            <div style={styles.buttonContainer}>
              <button onClick={handleAcceptCookies} style={styles.acceptButton}>
                Accept
              </button>
              <button onClick={handleRejectCookies} style={styles.rejectButton}>
                Reject
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// Styles for the component
const styles = {
  notificationContainer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "#ff0000", // Red color to match your branding
    color: "#fff",
    padding: "15px",
    textAlign: "center",
    zIndex: 1000,
  },
  notificationContent: {
    maxWidth: "800px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  message: {
    marginBottom: "10px",
  },
  buttonContainer: {
    display: "flex",
    gap: "10px",
  },
  acceptButton: {
    backgroundColor: "#fff",
    color: "#ff0000",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    fontWeight: "bold",
  },
  rejectButton: {
    backgroundColor: "transparent",
    color: "#fff",
    border: "1px solid #fff",
    padding: "10px 20px",
    cursor: "pointer",
    fontWeight: "bold",
  },
};

export default CookiesNotification;
