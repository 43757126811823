import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes for validation
import { useLocation } from "react-router-dom"; // Import useLocation from React Router
import Header from "../components/Header";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { useAuth } from "../context/AuthContext";
import { ChevronBarUp } from "react-bootstrap-icons";
import { localData } from "../context/processor";

const Layout = ({ children }) => {
  const location = useLocation(); // Get current location from React Router
  // State to handle the nav visibility, default open for large view
  const [navClosed, setNavClosed] = useState(
    window.innerWidth < 850
      ? true
      : localData("get", "layoutNavClosed") ?? false
  );
  const [showScrollToTop, setShowScrollToTop] = useState(false); // State to handle scroll-to-top visibility
  const { authenticated } = useAuth();

  // Toggle function to open/close the nav
  const toggleNav = () => {
    setNavClosed((prevState) => {
      const newState = !prevState;
      if (window.innerWidth >= 850) {
        localData("save", "layoutNavClosed", newState);
      }
      return newState;
    });
  };

  // Scroll to top whenever location (URL) changes
  useEffect(() => {
    const mainContent = document.querySelector(".main");
    if (mainContent) {
      mainContent.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]); // Dependency on location to trigger the effect on route change

  // Show or hide the scroll-to-top button based on scrolling
  useEffect(() => {
    const mainContent = document.querySelector(".main");

    const handleScroll = () => {
      if (mainContent && mainContent.scrollTop > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    if (mainContent) {
      mainContent.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (mainContent) {
        mainContent.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // Scroll to top function for the button
  const scrollToTop = () => {
    const mainContent = document.querySelector(".main");
    if (mainContent) {
      mainContent.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <React.Fragment>
      {authenticated ? (
        <div>
          <Header navClosed={navClosed} toggleNav={toggleNav} />
          <div className="main-container">
            <Navigation navClosed={navClosed} toggleNav={toggleNav} />
            <div
              className="main"
              style={{
                position: "relative",
                overflowY: "auto",
                height: "calc(100vh - 100px)",
              }}
            >
              <div style={{ minHeight: "75VH" }}>{children}</div>
              <Footer />
            </div>
            {/* Scroll-to-top button */}
            {showScrollToTop && (
              <button
                onClick={scrollToTop}
                style={{
                  position: "fixed",
                  bottom: "50px",
                  right: "50px",
                  padding: "10px",
                  fontSize: "16px",
                  borderRadius: "50%",
                  color: "#fff",
                  border: "none",
                  cursor: "pointer",
                }}
                aria-label="Scroll to top"
              >
                <ChevronBarUp size={40} color="red" />
              </button>
            )}
          </div>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </React.Fragment>
  );
};

// Add PropTypes validation for the 'children' prop
Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
