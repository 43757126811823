import React, { useState, useEffect, useRef } from "react";
import { localData, runData } from "../context/processor";
import { setSession } from "../utils/auth";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";
import Logo from "../assets/logo.png"; // Placeholder logo image
import Office from "../assets/cqs-bg.jpg"; // Background image
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";

const Authenticate = () => {
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hasAccount, setHasAccount] = useState(null);
  const [userName, setUserName] = useState("");
  const [showFreeTrialModal, setShowFreeTrialModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const otpRefs = useRef([]);
  const otpKeys = [0, 1, 2, 3, 4, 5]; // Static incremental keys

  const [lastOtpRequestTime, setLastOtpRequestTime] = useState(null);
  const [trialFormPage, setTrialFormPage] = useState(1);
  const [otpRequested, setOtpRequested] = useState(false);
  const [trialFormData, setTrialFormData] = useState({
    companyName: "",
    companyEmail: "",
    serviceOffered: "",
    useCompanyEmailForLogin: true,
    userEmail: "",
    phone: "",
    firstName: "",
    lastName: "",
  });

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (!/^\d$/.test(value) && value !== "") return; // Allow only digits

    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);

    // Move to the next input field
    if (value && index < 5) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (!otp[index] && index > 0) {
        // Move to the previous field if current is empty
        otpRefs.current[index - 1].focus();
      }
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    } else if (e.key === "ArrowLeft" && index > 0) {
      // Move to the previous input on ArrowLeft
      otpRefs.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < 5) {
      // Move to the next input on ArrowRight
      otpRefs.current[index + 1].focus();
    }
  };

  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text").slice(0, 6); // Get first 6 characters
    if (!/^\d+$/.test(pastedData)) return; // Allow only digits

    const newOtp = [...otp];
    for (let i = 0; i < pastedData.length; i++) {
      newOtp[i] = pastedData[i];
      if (otpRefs.current[i]) {
        otpRefs.current[i].value = pastedData[i];
      }
    }
    setOtp(newOtp);

    // Focus the next empty input or the last one
    const nextIndex = pastedData.length >= 6 ? 5 : pastedData.length;
    if (otpRefs.current[nextIndex]) otpRefs.current[nextIndex].focus();
  };

  useEffect(() => {
    if (otpRefs.current[0]) {
      otpRefs.current[0].addEventListener("paste", handleOtpPaste);
    }
    return () => {
      if (otpRefs.current[0]) {
        otpRefs.current[0].removeEventListener("paste", handleOtpPaste);
      }
    };
  }, []);

  // Handle email check
  const handleEmailCheck = async (event) => {
    event.preventDefault();
    setProcessing(true);
    try {
      const data = {
        email: email.trim(),
      };
      const response = await runData(data, "/web/authenticate/check-email/");

      if (response.status === 200) {
        if (response.data.account === 1) {
          setHasAccount(true);
          setUserName(response.data.name);
        } else {
          setHasAccount(false);
          setShowFreeTrialModal(true);
        }
      } else {
        setReport({
          show: true,
          message:
            response.data.message || "An error occurred during email check.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message || "An unexpected error occurred during email check.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  // Handle login form submission
  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    try {
      const data = {
        email: email.trim(),
        password: password,
      };
      const response = await runData(data, "/web/authenticate/pin-login/");

      if (response.status === 200) {
        const { user } = response.data;

        // Get the current status of cookies
        const cookiesStatus = localData("get", "cookies");

        if (cookiesStatus === "accept") {
          // If cookies are accepted, proceed to set the session
          setSession(user);

          setReport({
            show: true,
            message: "You have been logged in successfully using password!",
            type: "success",
          });
        } else {
          // If cookies are not accepted, remove the cookies
          localData("remove", "cookies");

          // Optionally, you could also remove any existing session data for consistency
          localData("remove", "session");
          setReport({
            show: true,
            message:
              "Your request has been accepted, but you must accept cookies to continue with login.",
            type: "error",
          });
        }
      } else {
        setReport({
          show: true,
          message: response.data.message || "An error occurred during login.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message: err.message || "An unexpected error occurred during login.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  // Handle OTP request
  const handleRequestOtp = async () => {
    setShowOtpModal(false);
    const TWO_MINUTES = 2 * 60 * 1000; // Time in milliseconds

    const now = Date.now();

    // Check if there was a recent OTP request
    if (lastOtpRequestTime && now - lastOtpRequestTime < TWO_MINUTES) {
      const remainingTime = Math.ceil(
        (TWO_MINUTES - (now - lastOtpRequestTime)) / 60000
      );
      setReport({
        show: true,
        message: `Please wait ${remainingTime} minute(s) before requesting a new OTP.`,
        type: "error",
      });
      return;
    }

    setProcessing(true);
    try {
      const data = {
        email: email.trim(),
      };
      const response = await runData(data, "/web/authenticate/request-otp/");

      if (response.status === 200) {
        setOtpRequested(true);
        setLastOtpRequestTime(now); // Store the last request time
        setShowOtpModal(true);
      } else {
        setReport({
          show: true,
          message:
            response.data.message || "An error occurred while requesting OTP.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message || "An unexpected error occurred while requesting OTP.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  // Handle OTP login submission
  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    setShowOtpModal(false);
    setProcessing(true);
    try {
      const data = {
        email: email.trim(),
        otp: otp.join("").trim(),
      };
      const response = await runData(data, "/web/authenticate/otp-login/");

      if (response.status === 200) {
        const { user } = response.data;

        // Get the current status of cookies
        const cookiesStatus = localData("get", "cookies");

        if (cookiesStatus === "accept") {
          // If cookies are accepted, proceed to set the session
          setSession(user);

          setReport({
            show: true,
            message: "You have been logged in successfully using OTP!",
            type: "success",
          });
        } else {
          // If cookies are not accepted, remove the cookies
          localData("remove", "cookies");

          // Optionally, you could also remove any existing session data for consistency
          localData("remove", "session");
          setReport({
            show: true,
            message:
              "Your request has been accepted, but you must accept cookies to continue with login.",
            type: "error",
          });
        }
      } else {
        setReport({
          show: true,
          message: response.data.message || "Invalid OTP. Please try again.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message || "An unexpected error occurred during OTP login.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  // Handle free trial form submission
  const handleFreeTrialSubmit = async (event) => {
    event.preventDefault();
    setShowFreeTrialModal(false);
    setProcessing(true);
    try {
      const response = await runData(
        trialFormData,
        "/web/authenticate/request-trial/"
      );

      if (response.status === 200) {
        setReport({
          show: true,
          message: response.data.message,
          type: "success",
        });
        setHasAccount(null);
      } else {
        setReport({
          show: true,
          message:
            response.data.message ||
            "An error occurred during free trial request.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message ||
          "An unexpected error occurred during free trial request.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  // Determine header text based on account status
  const getHeaderText = () => {
    if (hasAccount === null) {
      return "Let's Get Started";
    } else if (hasAccount) {
      return `Welcome back, ${userName}`;
    } else {
      return "Request Free Trial";
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Authenticate - Care Quality Support</title>
      </Helmet>
      <div className="vh-100 d-flex flex-column align-items-center justify-content-center primary-bg">
        <div className="row w-100 h-100">
          {/* Left Section */}
          <div
            className="col-md-6 d-none d-md-flex flex-column justify-content-center align-items-center text-white"
            style={{
              backgroundImage: `url(${Office})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            ></div>
            <h1
              className="text-center fw-bold mb-4"
              style={{
                textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
                zIndex: 1,
                fontSize: "2.5rem",
                letterSpacing: "1.2px",
              }}
            >
              Welcome to Care Quality Support
            </h1>
            <h3
              className="text-center fw-bold mt-4"
              style={{
                textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
                zIndex: 1,
                letterSpacing: "1.2px",
              }}
            >
              <a
                href="tel:02080642464"
                className="text-primary"
                style={{ textDecoration: "none" }}
              >
                Call 020 8064 2464 for help!
              </a>
            </h3>
            <img
              src={Logo}
              alt="Care Quality Support"
              className="position-absolute bottom-0 mb-4 bg-light"
              style={{ width: "250px", zIndex: 1, borderRadius: "10px" }}
            />
          </div>

          {/* Right Section */}
          <div
            className="col-md-6 d-flex align-items-center justify-content-center p-4"
            style={{ minHeight: "100vh" }}
          >
            <div
              className="login-card card p-4 shadow"
              style={{
                borderRadius: "12px",
                backgroundColor: "#ffffff",
                maxWidth: "450px",
                width: "100%",
                boxShadow: "0px 4px 10px rgba(255, 0, 0, 0.2)",
              }}
            >
              <h2
                className="mb-4 text-center"
                style={{
                  fontWeight: 600,
                  color: "#333",
                  fontSize: "2rem",
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                }}
              >
                {getHeaderText()}
              </h2>
              <Form
                onSubmit={(e) => {
                  e.preventDefault(); // Always prevent default first
                  if (hasAccount === null) {
                    // Execute email check and set `hasAccount` state
                    handleEmailCheck(e);
                  } else if (hasAccount) {
                    // Proceed to login submission if account exists
                    handleLoginSubmit(e);
                  } else {
                    // Open free trial modal if no account exists
                    setShowFreeTrialModal(true);
                  }
                }}
                style={{ marginTop: "10px" }}
              >
                <Form.Group className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <Form.Label style={{ fontSize: "1rem", color: "#666" }}>
                      {hasAccount ? "Email" : "What's your email address?"}
                    </Form.Label>
                    {hasAccount && (
                      <Button
                        variant="link"
                        onClick={() => {
                          setHasAccount(null);
                          setEmail(""); // Optional reset
                          setPassword(""); // Optional reset
                        }}
                        style={{
                          color: "#007bff",
                          textDecoration: "underline",
                          fontSize: "0.9rem",
                        }}
                      >
                        Not You, {userName}?
                      </Button>
                    )}
                  </div>
                  <Form.Control
                    type="email"
                    placeholder="Enter email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    disabled={hasAccount !== null}
                    style={{
                      padding: "15px",
                      fontSize: "1rem",
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                      marginTop: "8px",
                    }}
                  />
                </Form.Group>

                {hasAccount && (
                  <Form.Group className="mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Label style={{ fontSize: "1rem", color: "#666" }}>
                        Password
                      </Form.Label>
                      <Button
                        variant="link"
                        onClick={() => setShowOtpModal(true)}
                        style={{
                          color: "#007bff",
                          textDecoration: "underline",
                          fontSize: "0.9rem",
                        }}
                      >
                        Forgot password?
                      </Button>
                    </div>
                    <Form.Control
                      type="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      style={{
                        padding: "15px",
                        fontSize: "1rem",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        marginTop: "8px",
                      }}
                    />
                  </Form.Group>
                )}

                <div className="d-flex flex-column mt-3">
                  {hasAccount === null && (
                    <Button type="submit" className="btn btn-primary w-100">
                      Continue
                    </Button>
                  )}

                  {hasAccount === true && (
                    <Button type="submit" className="btn btn-primary w-100">
                      Login
                    </Button>
                  )}

                  {hasAccount === false && (
                    <Button
                      type="button"
                      className="btn btn-primary w-100"
                      onClick={() => setShowFreeTrialModal(true)}
                    >
                      Request Free Trial
                    </Button>
                  )}
                </div>
              </Form>

              <div
                className="divider my-4 text-center position-relative"
                style={{ margin: "30px 0" }}
              >
                <span
                  className="text-muted"
                  style={{
                    backgroundColor: "#ffffff",
                    padding: "0 10px",
                    position: "relative",
                  }}
                >
                  Important Information
                </span>
                <hr
                  style={{
                    borderTop: "1px solid #ddd",
                    position: "absolute",
                    top: "50%",
                    width: "100%",
                    left: 0,
                    margin: 0,
                    zIndex: -1,
                  }}
                />
              </div>

              <div>
                <p
                  style={{
                    fontSize: "0.9rem",
                    color: "#555",
                    lineHeight: 1.6,
                    textAlign: "justify",
                  }}
                >
                  Welcome to the Care Quality Support document management system
                  for health and social care policies and procedures. Whether
                  you are a new or existing user, please enter your email
                  address above to begin.
                </p>
              </div>
              <Modal
                show={showFreeTrialModal}
                onHide={() => setShowFreeTrialModal(false)}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Request 7-Day Free Trial</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {trialFormPage === 1 && (
                    <Form>
                      <Form.Group className="mb-4">
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Company Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter company name"
                          value={trialFormData.companyName}
                          onChange={(e) =>
                            setTrialFormData({
                              ...trialFormData,
                              companyName: e.target.value,
                            })
                          }
                          required
                          style={{
                            borderRadius: "8px",
                            padding: "10px",
                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Company Services
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter services offered"
                          value={trialFormData.serviceOffered}
                          onChange={(e) =>
                            setTrialFormData({
                              ...trialFormData,
                              serviceOffered: e.target.value,
                            })
                          }
                          required
                          style={{
                            borderRadius: "8px",
                            padding: "10px",
                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Company Email
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter company email"
                          value={trialFormData.companyEmail}
                          onChange={(e) =>
                            setTrialFormData({
                              ...trialFormData,
                              companyEmail: e.target.value,
                            })
                          }
                          required
                          style={{
                            borderRadius: "8px",
                            padding: "10px",
                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Form.Check
                          type="checkbox"
                          label="Use company email for login?"
                          checked={trialFormData.useCompanyEmailForLogin}
                          onChange={(e) =>
                            setTrialFormData({
                              ...trialFormData,
                              useCompanyEmailForLogin: e.target.checked,
                            })
                          }
                          style={{ fontWeight: "bold" }}
                        />
                      </Form.Group>
                    </Form>
                  )}
                  {trialFormPage === 2 && (
                    <Form>
                      {!trialFormData.useCompanyEmailForLogin && (
                        <Form.Group className="mb-4">
                          <Form.Label style={{ fontWeight: "bold" }}>
                            User Email
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter user email"
                            value={trialFormData.userEmail}
                            onChange={(e) =>
                              setTrialFormData({
                                ...trialFormData,
                                userEmail: e.target.value,
                              })
                            }
                            required
                            style={{
                              borderRadius: "8px",
                              padding: "10px",
                              boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        </Form.Group>
                      )}
                      <Form.Group className="mb-4">
                        <Form.Label style={{ fontWeight: "bold" }}>
                          First Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter first name"
                          value={trialFormData.firstName}
                          onChange={(e) =>
                            setTrialFormData({
                              ...trialFormData,
                              firstName: e.target.value,
                            })
                          }
                          required
                          style={{
                            borderRadius: "8px",
                            padding: "10px",
                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Last Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter last name"
                          value={trialFormData.lastName}
                          onChange={(e) =>
                            setTrialFormData({
                              ...trialFormData,
                              lastName: e.target.value,
                            })
                          }
                          required
                          style={{
                            borderRadius: "8px",
                            padding: "10px",
                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Form.Label style={{ fontWeight: "bold" }}>
                          WhatsApp Number
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter WhatsApp number"
                          value={trialFormData.phone}
                          onChange={(e) =>
                            setTrialFormData({
                              ...trialFormData,
                              phone: e.target.value,
                            })
                          }
                          required
                          style={{
                            borderRadius: "8px",
                            padding: "10px",
                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                      </Form.Group>
                    </Form>
                  )}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                  {trialFormPage === 1 && (
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setHasAccount(null); // Reset the account state
                        setShowFreeTrialModal(false); // Close the modal
                      }}
                      style={{
                        borderRadius: "8px",
                        fontSize: "0.95rem",
                        padding: "10px 20px",
                      }}
                    >
                      I'm Registered
                    </Button>
                  )}
                  {trialFormPage > 1 && (
                    <Button
                      variant="secondary"
                      onClick={() => setTrialFormPage(trialFormPage - 1)}
                      style={{
                        borderRadius: "8px",
                        fontSize: "0.95rem",
                        padding: "10px 20px",
                      }}
                    >
                      Back
                    </Button>
                  )}
                  {trialFormPage < 2 ? (
                    <Button
                      variant="primary"
                      onClick={() => setTrialFormPage(trialFormPage + 1)}
                      style={{
                        borderRadius: "8px",
                        fontSize: "0.95rem",
                        padding: "10px 20px",
                      }}
                    >
                      Continue
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      onClick={handleFreeTrialSubmit}
                      style={{
                        borderRadius: "8px",
                        fontSize: "0.95rem",
                        padding: "10px 20px",
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </Modal.Footer>
              </Modal>

              <Modal
                show={showOtpModal}
                onHide={() => setShowOtpModal(false)}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Request OTP</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p
                    style={{
                      fontSize: "0.9rem",
                      color: "#666",
                      marginBottom: "20px",
                    }}
                  >
                    {userName}, to log in securely, click "Request OTP." An OTP
                    (One-Time Password) will be sent to your registered email
                    address. Enter the OTP below to proceed. You can change your
                    password in your profile settings after logging in.
                  </p>

                  {otpRequested && (
                    <p className="text-success">
                      An OTP has been sent to your email. Please enter it below
                      to proceed with login. Note that the OTP is valid for 5
                      minutes, and you may request a new OTP every 2 minutes if
                      needed.
                    </p>
                  )}

                  <Form onSubmit={handleOtpSubmit}>
                    {!otpRequested && (
                      <div className="text-center">
                        <Button
                          variant="primary"
                          onClick={handleRequestOtp}
                          className="w-100"
                          style={{
                            fontSize: "1rem",
                            padding: "12px",
                            borderRadius: "8px",
                          }}
                        >
                          Request OTP
                        </Button>
                      </div>
                    )}

                    {otpRequested && (
                      <>
                        <Form.Group className="mt-4 mb-5">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "1rem",
                            }}
                          >
                            <Form.Label
                              style={{
                                fontWeight: "bold",
                                fontSize: "0.95rem",
                              }}
                            >
                              Enter OTP
                            </Form.Label>
                            <Button
                              className="text-primary"
                              style={{ cursor: "pointer", fontSize: "0.85rem" }}
                              onClick={() => {
                                setOtpRequested(false);
                              }}
                            >
                              Request new OTP
                            </Button>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "10px",
                              marginTop: "10px",
                            }}
                          >
                            {otpKeys.map((key, index) => (
                              <input
                                key={`otp-input-${key}`} // Use incremental number in key
                                type="text"
                                maxLength="1"
                                ref={(ref) => (otpRefs.current[index] = ref)} // Assign refs dynamically
                                value={otp[index] || ""}
                                onChange={(e) => handleOtpChange(e, index)}
                                onKeyDown={(e) => handleOtpKeyDown(e, index)}
                                style={{
                                  width: "40px",
                                  height: "50px",
                                  textAlign: "center",
                                  fontSize: "1.5rem",
                                  borderRadius: "8px",
                                  border: "1px solid #ccc",
                                  outline: "none",
                                  transition: "border-color 0.3s ease",
                                }}
                              />
                            ))}
                          </div>
                        </Form.Group>
                        <div className="d-flex justify-content-between">
                          <Button
                            variant="secondary"
                            onClick={() => setShowOtpModal(false)}
                            style={{
                              borderRadius: "8px",
                              fontSize: "0.95rem",
                              padding: "10px 20px",
                              marginRight: "10px",
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            className="btn btn-primary"
                            style={{
                              fontSize: "1rem",
                              padding: "10px 20px",
                              borderRadius: "8px",
                            }}
                          >
                            Login with OTP
                          </Button>
                        </div>
                      </>
                    )}
                  </Form>
                </Modal.Body>
              </Modal>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "20px",
                fontSize: "0.9rem",
                color: "#666",
                textAlign: "center",
              }}
            >
              &copy; {new Date().getFullYear()} Care Quality Support – A GMT
              Networks Ltd Company (Registered in England & Wales: 14549850)
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Authenticate;
