import { localData } from "../context/processor";

let authChangeCallback = null;

/**
 * Checks if the user is authenticated by verifying the existence of session data.
 * @returns {boolean} - True if authenticated, otherwise false.
 */
export const isAuthenticated = () => {
  const session = localData("get", "session");

  // Return true if session data is available
  return session ? true : false;
};

/**
 * Sets the session data with user data.
 * @param {object} user - The user data to be stored.
 */
export const setSession = (user) => {
  // Save user data in session storage
  localData("save", "session", user);

  // Trigger authentication change callback
  if (authChangeCallback) authChangeCallback(true);
};

/**
 * Registers a callback to be invoked when authentication status changes.
 * @param {function} callback - The callback function to be invoked.
 * @returns {function} - A function to unregister the callback.
 */
export const onAuthChange = (callback) => {
  authChangeCallback = callback;
  return () => {
    authChangeCallback = null;
  };
};

/**
 * Logs the user out by removing session data.
 */
export const logout = () => {
  // Trigger authentication change callback to indicate logout
  if (authChangeCallback) authChangeCallback(false);

  // Remove user data from session storage
  localData("remove", "session");
};
