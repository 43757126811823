// Restricted.js - Page indicating user is not allowed to access the page
import React from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { LockFill } from "react-bootstrap-icons";

const Restricted = () => {
  return (
    <Container fluid className="vh-100 d-flex justify-content-center align-items-center">
      <Row>
        <Col className="text-center">
          <LockFill size={50} color="red" />
          <Alert variant="danger" className="mt-4">
            <h4>Access Denied</h4>
            <p>You do not have the required permissions to view this page.</p>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default Restricted;

