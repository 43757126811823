import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Card,
  Table,
  Button,
  Modal,
  Form,
  ListGroup,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Line } from "react-chartjs-2";
import { FaEdit } from "react-icons/fa";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register chart.js components globally
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CustomerPage = () => {
  const { customer } = useParams(); // Get customer ID from the route parameters

  // Sample customer data (would come from your API in a real app)
  const [customerData, setCustomerData] = useState({
    id: 1,
    reference: "CUST001",
    company: "XYZ Corp",
    description:
      "Policies & Procedures System for enhancing business efficiency and safety.",
    status: 1, // Assuming status: 1 = Active, 0 = Inactive
    date: "2021-05-20",
  });

  const [showEditModal, setShowEditModal] = useState(false); // Control the visibility of the edit modal
  const [formData, setFormData] = useState(customerData); // Local state for form data

  // Dummy chart data for the activity summary
  const chartData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Activity Data",
        data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 39],
        fill: false,
        backgroundColor: "rgb(75, 192, 192)", // Color of chart points
        borderColor: "rgba(75, 192, 192, 0.2)", // Color of the line
      },
    ],
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // You can replace this with your actual API call for updating customer details
    console.log("Submitted Data:", formData);

    // Simulate successful API response and update customer data
    setCustomerData(formData);
    setShowEditModal(false); // Close the modal after submission
  };

  return (
    <React.Fragment>
      {/* SEO: Set the page title */}
      <Helmet>
        <title>Business Customers - Care Quality Support</title>
      </Helmet>

      {/* Main container for the customer page */}
      <Container className="mb-4">
        {/* Row for Breadcrumb navigation */}
        <Row className="align-items-center mb-4">
          <Col className="mt-4">
            <Breadcrumb aria-label="breadcrumb">
              <Breadcrumb.Item href="/dashboard/">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item href="/customers/">Customers</Breadcrumb.Item>
              <Breadcrumb.Item active>Customer</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        {/* Row with Sidebar (Navigation) and Main Content Area */}
        <Row>
          {/* Sidebar with links to Profiles, Users, and Subscriptions */}
          <Col md={3} >
            <Card>
              <Card.Header as="h5">Customer Navigation</Card.Header>
              <ListGroup variant="flush" aria-label="Customer Navigation">
                <ListGroup.Item
                  as={Link}
                  to={`/customers/${customer}/`}
                  aria-label="View Profiles"
                  active
                >
                  Overview
                </ListGroup.Item>
                <ListGroup.Item
                  as={Link}
                  to={`/customers/${customer}/profiles`}
                  aria-label="View Profiles"
                >
                  Profiles
                </ListGroup.Item>
                <ListGroup.Item
                  as={Link}
                  to={`/customers/${customer}/users`}
                  aria-label="View Users"
                >
                  Users
                </ListGroup.Item>
                <ListGroup.Item
                  as={Link}
                  to={`/customers/${customer}/subscriptions`}
                  aria-label="View Subscriptions"
                >
                  Subscriptions
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>

          {/* Main Content: Customer Overview and Activity Summary */}
          <Col md={9}>
            {/* Customer Overview */}
            <Card className="mb-4">
              <Card.Header
                as="h5"
                className="d-flex justify-content-between align-items-center"
              >
                Customer Overview
                {/* Edit Button as an icon in the header */}
                  <Button
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowEditModal(true)}
                    aria-label="Edit Customer"
                  >
                    <FaEdit /> Edit
                  </Button>
              </Card.Header>
              <Card.Body>
                {/* Table for displaying customer details */}
                <Table className="table-borderless" responsive>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Company Name:</strong>
                      </td>
                      <td>{customerData.company}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Customer Reference:</strong>
                      </td>
                      <td>{customerData.reference}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Registration Date:</strong>
                      </td>
                      <td>
                        {new Date(customerData.date).toLocaleDateString()}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Account Status:</strong>
                      </td>
                      <td>
                        {customerData.status === 1 ? "Active" : "Inactive"}
                      </td>
                    </tr>
                  </tbody>
                </Table>

                {/* Full-width description below the table */}
                <Row className="mt-3">
                  <Col>
                    <p>
                      <strong>Business Description: </strong>
                      {customerData.description}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* Activity Summary Chart */}
            <Card>
              <Card.Body>
                <Card.Title>Activity Summary</Card.Title>
                <Line
                  data={chartData}
                  aria-label="Activity Summary Chart"
                  role="img"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Edit Customer Modal */}
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            {/* Editable fields */}
            <Form.Group className="mt-3">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={formData.company}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={formData.status}
                onChange={handleInputChange}
              >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </Form.Control>
            </Form.Group>

            {/* Form Buttons */}
            <div className="d-flex justify-content-between mt-4">
              {/* Cancel Button on the left */}
              <Button
                variant="secondary"
                onClick={() => setShowEditModal(false)}
                aria-label="Cancel Edit"
              >
                Cancel
              </Button>

              {/* Submit Button on the right */}
              <Button
                type="submit"
                className="btn btn-primary"
                aria-label="Submit Changes"
              >
                Save Changes
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default CustomerPage;
