import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import { FaAngleLeft, FaEdit } from "react-icons/fa";
import { localData, runData } from "../context/processor";
import userImage from "../assets/user-avatar.png";
import { NavLink } from "react-router-dom";
import { useProcessing } from "../context/ProcessingModal";
import { useReport } from "../context/ReportModal";

const UserProfile = () => {
  const [user, setUser] = useState(localData("get", "session"));
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [editField, setEditField] = useState("");
  const [editLabel, setEditLabel] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [showDropzoneModal, setShowDropzoneModal] = useState(false);
  const [otpSuccessMessage, setOtpSuccessMessage] = useState(false);
  const { setProcessing } = useProcessing();
  const { setReport } = useReport();
  const [lastOtpRequest, setLastOtpRequest] = useState({
    type: null,
    time: null,
  });
  const [otpRequested, setOtpRequested] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const otpRefs = useRef([]);
  const otpKeys = [0, 1, 2, 3, 4, 5]; // Static incremental keys

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (!/^\d$/.test(value) && value !== "") return; // Allow only digits

    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);

    // Move to the next input field
    if (value && index < 5) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (!otp[index] && index > 0) {
        // Move to the previous field if current is empty
        otpRefs.current[index - 1].focus();
      }
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    } else if (e.key === "ArrowLeft" && index > 0) {
      // Move to the previous input on ArrowLeft
      otpRefs.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < 5) {
      // Move to the next input on ArrowRight
      otpRefs.current[index + 1].focus();
    }
  };

  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text").slice(0, 6); // Get first 6 characters
    if (!/^\d+$/.test(pastedData)) return; // Allow only digits

    const newOtp = [...otp];
    for (let i = 0; i < pastedData.length; i++) {
      newOtp[i] = pastedData[i];
      if (otpRefs.current[i]) {
        otpRefs.current[i].value = pastedData[i];
      }
    }
    setOtp(newOtp);

    // Focus the next empty input or the last one
    const nextIndex = pastedData.length >= 6 ? 5 : pastedData.length;
    if (otpRefs.current[nextIndex]) otpRefs.current[nextIndex].focus();
  };

  useEffect(() => {
    if (otpRefs.current[0]) {
      otpRefs.current[0].addEventListener("paste", handleOtpPaste);
    }
    return () => {
      if (otpRefs.current[0]) {
        otpRefs.current[0].removeEventListener("paste", handleOtpPaste);
      }
    };
  }, []);

  const handleOtpRequest = async (type) => {
    const TWO_MINUTES = 2 * 60 * 1000; // Time in milliseconds
    const now = Date.now();

    // Check if there was a recent OTP request of the same type
    if (
      lastOtpRequest?.type === type &&
      lastOtpRequest?.time &&
      now - lastOtpRequest.time < TWO_MINUTES
    ) {
      const remainingTime = Math.ceil(
        (TWO_MINUTES - (now - lastOtpRequest.time)) / 60000
      );
      setReport({
        show: true,
        message: `Please wait ${remainingTime} minute(s) before requesting a new OTP.`,
        type: "error",
      });
      return;
    }

    // If the request passes, proceed to request an OTP
    if (type === "email") {
      setShowEmailModal(false);
    } else {
      setShowPasswordModal(false);
    }
    setProcessing(true);
    try {
      const response = await runData(
        { email: user.email, type: type },
        "/api/profile/request-otp/"
      );
      if (response.status === 200) {
        setOtpRequested(true);
        setOtpSuccessMessage(true);
        if (type === "email") {
          setShowEmailModal(true);
        } else {
          setShowPasswordModal(true);
        }
        // Set the last OTP request type and time
        setLastOtpRequest({ type, time: now });
      } else {
        setReport({
          show: true,
          message:
            response?.data?.message ||
            "An error occurred while requesting OTP.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message || "An unexpected error occurred while requesting OTP.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  // Validating user inputs based on field type
  const validateInput = (input, field) => {
    switch (field) {
      case "email":
        return input;
      case "phone":
        return input.replace(/[^0-9+]/g, "");
      default:
        return input;
    }
  };

  const handleEditClick = (field, label) => {
    setEditField(field);
    setEditLabel(label);
    setModalData(user[field] || "");
    if (field === "email") {
      setShowEmailModal(true);
    } else {
      setShowModal(true);
    }
  };

  const handleSave = async () => {
    const sanitizedData = validateInput(modalData);
    setShowModal(false);
    setProcessing(true);
    try {
      const data = {
        [editField]: sanitizedData,
      };
      const response = await runData(data, "/api/profile/update/");

      if (response.status === 200) {
        setUser({ ...user, [editField]: sanitizedData });
        localData("save", "session", { ...user, [editField]: sanitizedData });
        setReport({
          show: true,
          message: `${editLabel} updated successfully.`,
          type: "success",
        });
      } else {
        setReport({
          show: true,
          message:
            response?.data?.message ||
            "An error occurred while updating the profile.",
          type: "error",
        });
      }
    } catch (err) {
      setReport({
        show: true,
        message:
          err.message ||
          "An unexpected error occurred while updating the profile.",
        type: "error",
      });
    } finally {
      setProcessing(false);
    }
  };

  const handlePasswordChange = () => {
    setOtpRequested(false); // Reset OTP request state
    setShowPasswordModal(true);
  };

  const handlePasswordSave = async () => {
    if (otp && newPassword.length >= 8) {
      setShowPasswordModal(false);
      setProcessing(true);
      try {
        const data = {
          otp: validateInput(otp.join("").trim()),
          newPassword: newPassword,
        };
        const response = await runData(data, "/api/profile/update-password/");

        if (response.status === 200) {
          setReport({
            show: true,
            message: `Password updated successfully.`,
            type: "success",
          });
        } else {
          setReport({
            show: true,
            message:
              response?.data?.message ||
              "An error occurred while changing the password.",
            type: "error",
          });
        }
      } catch (err) {
        setReport({
          show: true,
          message:
            err.message ||
            "An unexpected error occurred while changing the password.",
          type: "error",
        });
      } finally {
        setProcessing(false);
      }
    } else {
      setReport({
        show: true,
        message:
          "Please fill out all fields correctly. Password must be at least 8 characters.",
        type: "warning",
      });
    }
  };

  const handleEmailSave = async () => {
    if (otp && newEmail) {
      setShowEmailModal(false);
      setProcessing(true);
      try {
        const data = {
          otp: validateInput(otp.join("").trim()),
          email: user.email,
          newEmail: newEmail,
        };
        const response = await runData(data, "/api/profile/update-email/");

        if (response.status === 200) {
          setUser({ ...user, email: newEmail });
          localData("save", "session", { ...user, email: newEmail });
          setReport({
            show: true,
            message: `Email updated successfully to ${newEmail}.`,
            type: "success",
          });
        } else {
          setReport({
            show: true,
            message:
              response?.data?.message ||
              "An error occurred while changing the email.",
            type: "error",
          });
        }
      } catch (err) {
        setReport({
          show: true,
          message:
            err.message ||
            "An unexpected error occurred while changing the email.",
          type: "error",
        });
      } finally {
        setProcessing(false);
      }
    } else {
      setReport({
        show: true,
        message: "Please fill out all fields correctly.",
        type: "warning",
      });
    }
  };

  const handleAvatarDrop = async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (file.size > 2 * 1024 * 1024) {
        setReport({
          show: true,
          message: "File size exceeds 2MB. Please select a smaller file.",
          type: "warning",
        });
        return;
      }
      setShowDropzoneModal(false);
      setProcessing(true);
      try {
        const data = {
          avatar: file,
        };
        const response = await runData(data, "/api/profile/update-avatar/");

        if (response.status === 200) {
          setUser({ ...user, avatar: response.data.avatar });
          localData("save", "session", {
            ...user,
            avatar: response.data.avatar,
          });
          setReport({
            show: true,
            message: "Avatar uploaded successfully.",
            type: "success",
          });
        } else {
          setReport({
            show: true,
            message:
              response?.data?.message ||
              "An error occurred while uploading the avatar.",
            type: "error",
          });
        }
      } catch (err) {
        setReport({
          show: true,
          message:
            err.message ||
            "An unexpected error occurred while uploading the avatar.",
          type: "error",
        });
      } finally {
        setProcessing(false);
      }
    }
  };

  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col>
          <Card className="mb-4" style={{ position: "relative" }}>
            {/* Background Cover Image */}
            <div
              style={{
                position: "relative",
                height: "200px",
                overflow: "hidden",
                background: "rgba(247, 237, 237, 0.72)",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                  zIndex: 1,
                }}
              >
                <img
                  src={user.avatar || userImage}
                  alt="User Avatar"
                  className="img-fluid rounded-circle mb-3"
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "cover",
                    border: "5px solid white",
                  }}
                />
                <Button
                  onClick={() => setShowDropzoneModal(true)}
                  style={{ cursor: "pointer", position: "relative" }}
                >
                  <FaEdit size={20} />
                </Button>
              </div>
              {/* Back Button and Title */}
              <div
                className="d-flex justify-content-between align-items-center"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  padding: "10px",
                  zIndex: 2,
                }}
              >
                <div className="d-flex align-items-center">
                  <NavLink
                    title="Back"
                    className="active"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      borderRight: "solid 2px red",
                      marginRight: "10px",
                    }}
                    to={`/`}
                  >
                    <FaAngleLeft style={{ marginRight: "0.5rem" }} /> Back
                  </NavLink>
                  <h4 style={{ fontWeight: "bold" }}>Manage Profile</h4>
                </div>
                <Button
                  variant="danger"
                  onClick={handlePasswordChange}
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                >
                  Change Password
                </Button>
              </div>
            </div>
            <Card.Body>
              <Row>
                <Col md={12}>
                  <div className="user-info-container">
                    {[
                      { label: "Title", value: user.title, field: "title" },
                      { label: "Name", value: user.fname, field: "fname" },
                      {
                        label: "Surname",
                        value: user.lname,
                        field: "lname",
                      },
                      { label: "Phone", value: user.phone, field: "phone" },
                      { label: "Email", value: user.email, field: "email" },
                    ].map(({ label, value, field }) => (
                      <div
                        className="user-info-item d-flex align-items-center mb-3"
                        key={field}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ width: "100%" }}
                        >
                          <div
                            style={{ minWidth: "150px", fontWeight: "bold" }}
                          >
                            {label}:
                          </div>{" "}
                          {/* Label on the far left */}
                          <div className="ml-auto" style={{ width: "33%" }}>
                            {value}
                          </div>{" "}
                          {/* Value offset to the right */}
                        </div>
                        <Button
                          variant="link"
                          onClick={() => handleEditClick(field, label)}
                          style={{ marginLeft: "10px" }}
                        >
                          Edit
                        </Button>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal for Editing Fields */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit {editLabel.charAt(0).toUpperCase() + editLabel.slice(1)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicInput">
              <Form.Label>
                {editLabel.charAt(0).toUpperCase() + editLabel.slice(1)}
              </Form.Label>
              <Form.Control
                type="text"
                value={modalData}
                onChange={(e) => setModalData(validateInput(e.target.value))}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Changing Password */}
      <Modal
        show={showPasswordModal}
        onHide={() => setShowPasswordModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!otpRequested ? (
            <div>
              <p>
                To change your password, you must first request a one-time
                password (OTP). Please click the button below to receive your
                OTP.
              </p>
              <div className="d-flex justify-content-between">
                <Button
                  variant="secondary"
                  onClick={() => setShowPasswordModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => handleOtpRequest("password")}
                >
                  Request OTP
                </Button>
              </div>
            </div>
          ) : (
            <Form>
              {otpSuccessMessage && (
                <p style={{ color: "green", textAlign: "center" }}>
                  OTP sent successfully. Please enter it below.
                </p>
              )}
              <Form.Group controlId="formOtpInput" className="mt-4 mb-5">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "1rem",
                  }}
                >
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "0.95rem",
                    }}
                  >
                    Enter OTP
                  </Form.Label>
                  <Button
                    className="text-primary"
                    style={{ cursor: "pointer", fontSize: "0.85rem" }}
                    onClick={() => {
                      setOtpRequested(false);
                    }}
                  >
                    Request new OTP
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  {otpKeys.map((key, index) => (
                    <input
                      key={`otp-input-${key}`} // Use incremental number in key
                      type="text"
                      maxLength="1"
                      ref={(ref) => (otpRefs.current[index] = ref)} // Assign refs dynamically
                      value={otp[index] || ""}
                      onChange={(e) => handleOtpChange(e, index)}
                      onKeyDown={(e) => handleOtpKeyDown(e, index)}
                      style={{
                        width: "40px",
                        height: "50px",
                        textAlign: "center",
                        fontSize: "1.5rem",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        outline: "none",
                        transition: "border-color 0.3s ease",
                      }}
                    />
                  ))}
                </div>
              </Form.Group>
              <Form.Group controlId="formNewPasswordInput">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        {otpRequested && (
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={() => setShowPasswordModal(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handlePasswordSave}>
              Save Password
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      {/* Modal for Changing Email */}
      <Modal
        show={showEmailModal}
        onHide={() => setShowEmailModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!otpRequested ? (
            <div>
              <p>
                To change your email, you must first request a one-time password
                (OTP). Please click the button below to receive your OTP.
              </p>
              <div className="d-flex justify-content-between">
                <Button
                  variant="secondary"
                  onClick={() => setShowEmailModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => handleOtpRequest("email")}
                >
                  Request OTP
                </Button>
              </div>
            </div>
          ) : (
            <Form>
              {otpSuccessMessage && (
                <p style={{ color: "green", textAlign: "center" }}>
                  OTP sent successfully. Please enter it below.
                </p>
              )}
              <Form.Group controlId="formOtpInput" className="mt-4 mb-5">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "1rem",
                  }}
                >
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "0.95rem",
                    }}
                  >
                    Enter OTP
                  </Form.Label>
                  <Button
                    className="text-primary"
                    style={{ cursor: "pointer", fontSize: "0.85rem" }}
                    onClick={() => {
                      setOtpRequested(false);
                    }}
                  >
                    Request new OTP
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  {otpKeys.map((key, index) => (
                    <input
                      key={`otp-input-${key}`} // Use incremental number in key
                      type="text"
                      maxLength="1"
                      ref={(ref) => (otpRefs.current[index] = ref)} // Assign refs dynamically
                      value={otp[index] || ""}
                      onChange={(e) => handleOtpChange(e, index)}
                      onKeyDown={(e) => handleOtpKeyDown(e, index)}
                      style={{
                        width: "40px",
                        height: "50px",
                        textAlign: "center",
                        fontSize: "1.5rem",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        outline: "none",
                        transition: "border-color 0.3s ease",
                      }}
                    />
                  ))}
                </div>
              </Form.Group>
              <Form.Group controlId="formNewEmailInput">
                <Form.Label>New Email</Form.Label>
                <Form.Control
                  type="email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        {otpRequested && (
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={() => setShowEmailModal(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleEmailSave}>
              Save Email
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      {/* Modal for Dropzone */}
      <Modal
        show={showDropzoneModal}
        onHide={() => setShowDropzoneModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Avatar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropzone
            onDrop={handleAvatarDrop}
            multiple={false}
            accept={{ "image/*": [".jpeg", ".jpg", ".png", ".gif"] }}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className="dropzone"
                style={{
                  border: "2px dashed #007bff",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <input {...getInputProps()} />
                <p>Drag 'n' drop an image here, or click to select one</p>
              </div>
            )}
          </Dropzone>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default UserProfile;
