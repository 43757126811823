// ErrorPage.js - Page showing an error preventing the content from being shown
import React from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { ExclamationTriangleFill } from "react-bootstrap-icons";

const ErrorPage = () => {
  return (
    <Container fluid className="vh-100 d-flex justify-content-center align-items-center">
      <Row>
        <Col className="text-center">
          <ExclamationTriangleFill size={50} color="orange" />
          <Alert variant="warning" className="mt-4">
            <h4>Unexpected Error</h4>
            <p>Something went wrong, and the page cannot be displayed. Please try again later.</p>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorPage;