import React, { createContext, useContext, useState, useEffect } from "react";
import { localData } from "./processor";

// Create a UserContext
const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(localData("get", "session"));

  // Function to update the user (e.g., after avatar update)
  const updateUser = (newUserData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...newUserData,
    }));
    // Persist the user data in local storage or session
    localData("save", "session", {
      ...user,
      ...newUserData,
    });
  };

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUser = () => {
    return useContext(UserContext);
  };