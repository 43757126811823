// File path: /src/components/Footer.js

import React from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="mt-5">
      <Container fluid></Container>
    </footer>
  );
};

export default Footer;
